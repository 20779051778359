import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
  ElementRef
} from '@angular/core';
import {
  FlightItinerary,
  ServiceType,
  EnterpriseSearchService,
  HelperRoutines,
  WithSubscriptionComponent,
  FlightJourney,
  ModalOpenerService,
  UserService
} from '@sabstravtech/obtservices/angular';
import {
  FlightEnterpriseSearchInterface,
  FlightItineraryWithExtensions,
  FlightTempParams,
  CombinedFlight,
  FlightDirectionEnum,
  RuleActionExtension,
  IHotelResultsConfiguration
} from '@sabstravtech/obtservices/base';
import moment from 'moment';
import { LightningModalTypes } from '../../../../vendor/classes/modal-types.enum';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../../../../vendor/services/theme.service';
import { LightningUserFavorurite } from '../../../../vendor/classes/user-favourite.enum';

@Component({
  selector: 'app-flight-display',
  templateUrl: './flight-display.component.html',
  styleUrls: ['./flight-display.component.scss']
})
export class FlightDisplayComponent extends WithSubscriptionComponent implements OnInit, OnChanges {
  get flight(): FlightItineraryWithExtensions {
    return this.combinedFlight.flights[0] as FlightItineraryWithExtensions;
  }
  @Input() gmt: boolean = false;
  @Input() isDualFlight: boolean;
  @Input() isDualReturn: boolean;
  @Input() combinedFlight: CombinedFlight;
  @Input() flightIndex: number;
  @Input() isExchange = false;
  @Input() isPricingExchange = false;
  @Output() selectExchangeFlight: EventEmitter<FlightItineraryWithExtensions> = new EventEmitter();
  @Output() gmtSelectedFlight: EventEmitter<FlightItineraryWithExtensions> = new EventEmitter();
  @Output() selectedMultiCityFlight: EventEmitter<FlightItineraryWithExtensions> = new EventEmitter();
  @Output() deselectMultiCityFlight: EventEmitter<FlightItineraryWithExtensions> = new EventEmitter();
  @Input() disableItems: boolean = false;
  @Input() deselectItem: boolean = false;
  @Input() selectedItemsId: number[] = [];
  ServiceType: typeof ServiceType = ServiceType;
  showLegs = false;
  showDetail = false;
  searchParams: FlightEnterpriseSearchInterface;
  showFaresBlock = false;
  rules: any = {};
  lastInboundFlightJourney: FlightJourney;
  lastOutboundFlightJourney: FlightJourney;
  search: FlightTempParams;
  selectedDSFlight = false;
  canOverride: boolean = false;
  isRetrunWarningOpen = false;
  constructor(
    public searchService: EnterpriseSearchService,
    private helpers: HelperRoutines,
    private modalService: ModalOpenerService,
    private userService: UserService,
    private router: Router,
    private translateService: TranslateService,
    private element: ElementRef,
    public themeService: ThemeService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(this.flight.uid);
    if (this.searchParams) {
      this.greenChoiceSetup();
    }
    if (changes.combinedFlight) {
      this.lastOutboundFlightJourney = changes.combinedFlight.currentValue.flights[0].outboundFlights[this.flight.outboundFlights.length - 1];
    }
  }

  ngOnInit(): void {
    this.canOverride = this.userService.canOverride();
    this.searchParams = this.searchService.searches[ServiceType.Flight];
    this.search = this.searchParams.originalUserSearch;

    this.greenChoiceSetup();
    if (this.flight.inboundFlights.length) {
      this.lastInboundFlightJourney =
        this.flight.inboundFlights[this.flight.inboundFlights.length - 1];
    }

    if (this.flight.outboundFlights.length) {
      this.lastOutboundFlightJourney =
        this.flight.outboundFlights[this.flight.outboundFlights.length - 1];
    }

    this.applyCustomCSS();
  }

  greenChoiceSetup(): void {
    if (this.isExchange) {
      return;
    }

    try {
      if (!this.isDualFlight && !this.isDualReturn && !this.searchParams.bySchedule) {
        this.combinedFlight.flights[0] = Object.assign(
          this.searchParams.greenerChoice.makeReturnOutbound(this.flight.co2PerPassenger),
          this.flight
        );
      }

      if (this.searchParams.bySchedule) {
        this.combinedFlight.flights[0] = Object.assign(
          this.flight,
          this.searchParams.greenerChoice.makeSearchBySchedule(this.searchParams.selectedSegment, this.flight.co2PerPassenger)
        );
      }

      if (this.isDualReturn) {
        // console.log('HERE');
        this.combinedFlight.flights[0] = Object.assign(
          this.searchParams.greenerChoice.makeMulti(1, this.flight.co2PerPassenger),
          this.flight
        );
      }

      if (this.isDualFlight) {
        this.combinedFlight.flights[0] = Object.assign(
          this.searchParams.greenerChoice.makeMulti(0, this.flight.co2PerPassenger),
          this.flight
        );
      }
    } catch (error) {
      console.warn(
        `'+++ error determeming greenerchoice - might not have setup correctly: ${error} +++`
      );
    }
  }

  async checkDualClassAndSelect(flightFare: FlightItineraryWithExtensions): Promise<void> {
    if (this.showAltClass(flightFare)) {
      this.modalService
        .open(
          LightningModalTypes.ModalConfirmComponent,
          { windowClass: 'fare-rules-modal', centered: true },
          {
            message_header: 'Warning!',
            message:
              'Please note you have selected a different class for the outbound and inbound flight. To continue with your current selection click OK, to select an alternative please select Cancel.',
            btnOkText: 'Ok',
            btnCancelText: 'Cancel'
          }
        )
        .then(async result => {
          if (result) {
            await this.selectedFlight(flightFare);
          }
        });
    } else {
      await this.selectedFlight(flightFare);
    }
  }

  async selectedFlight(flightFare: FlightItineraryWithExtensions): Promise<void> {
    if (this.isExchange) {
      if (!this.isPricingExchange) {
        this.selectExchangeFlight.emit(flightFare);
      }
      return;
    }
    if (this.checkFlightReturnAirport(flightFare)) {
      this.modalService
        .open(
          LightningModalTypes.ModalConfirmComponent,
          { centered: true },
          {
            message_header: this.translateService.instant('Warning!'),
            message: this.translateService.instant(
              'Your return airport is not the same as your departure airport, would you like to select this flight option anyway?'
            ),
            btnCancelText: this.translateService.instant('No'),
            btnOkText: this.translateService.instant('Yes')
          }
        )
        .then(result => {
          if (result) {
            this.showDetail = false;
            if (this.gmt) {
              this.gmtSelectedFlight.emit(flightFare);
            } else {
              if (this.isDualFlight) {
                this.selectDualSingle(flightFare);
              } else if (this.searchParams.selectedFlight.value) {
                const depart = this.searchParams.selectedFlight
                  .value as FlightItineraryWithExtensions;
                const isRetrunBeforeDepart = moment(
                  depart.outboundFlights[0].dateTimeDeparting
                ).isAfter(moment(flightFare.outboundFlights[0].dateTimeDeparting));
                if (isRetrunBeforeDepart) {
                  this.dateTimeError();
                } else {
                  this.addToBasketDSFlight(flightFare);
                }
              } else if (flightFare.outboundFlights.length && !flightFare.inboundFlights?.length) {
                this.addToBasket(flightFare);
              } else {
                const isRetrunBeforeDepart = moment(
                  flightFare.outboundFlights[0].dateTimeDeparting
                ).isAfter(moment(flightFare.inboundFlights[0].dateTimeDeparting));
                if (isRetrunBeforeDepart) {
                  this.dateTimeError();
                } else {
                  this.addToBasket(flightFare);
                }
              }
            }
          }
        });
    } else {
      this.showDetail = false;
      if (this.gmt) {
        this.gmtSelectedFlight.emit(flightFare);
      } else {
        if (this.isDualFlight) {
          this.selectDualSingle(flightFare);
        } else if (this.searchParams.selectedFlight.value) {
          const depart = this.searchParams.selectedFlight.value as FlightItineraryWithExtensions;
          const isRetrunBeforeDepart = moment(depart.outboundFlights[0].dateTimeDeparting).isAfter(
            moment(flightFare.outboundFlights[0].dateTimeDeparting)
          );
          if (isRetrunBeforeDepart) {
            this.dateTimeError();
          } else {
            this.addToBasketDSFlight(flightFare);
          }
        } else if (flightFare.outboundFlights.length && !flightFare.inboundFlights?.length) {
          this.addToBasket(flightFare);
        } else {
          const isRetrunBeforeDepart = moment(
            flightFare.outboundFlights[0].dateTimeDeparting
          ).isAfter(moment(flightFare.inboundFlights[0].dateTimeDeparting));
          if (isRetrunBeforeDepart) {
            this.dateTimeError();
          } else {
            this.addToBasket(flightFare);
          }
        }
      }
    }
  }

  async addToBasket(flightFare: FlightItineraryWithExtensions): Promise<void> {
    console.log('+++ Adding to basket: ', flightFare, ' +++');
    const flightCopy = <FlightItineraryWithExtensions>this.helpers.clone(flightFare);

    flightCopy.outboundFlights.forEach(flight => {
      if (!flight.destinationTerminal) {
        flight.destinationTerminal = '';
      }
    });

    if (flightCopy.inboundFlights?.length) {
      flightCopy.inboundFlights.forEach(flight => {
        if (!flight.destinationTerminal) {
          flight.destinationTerminal = '';
        }
      });
    }
    flightCopy.requiresReasonKeys = flightFare.requiresReasonKeys;
    flightCopy.requiresReasonMessages = flightFare.requiresReasonMessages;
    flightCopy.unavailable = flightFare?.unavailable;
    flightCopy.unavailableMessage = flightFare?.unavailableMessage;
    const showHotelModel = !(this.userService.getUserFavoriteObject<IHotelResultsConfiguration>(LightningUserFavorurite.HotelResultsFormConfiguration)?.disableRecommendHotelModal as { disableHotelModal: boolean; })?.disableHotelModal;
    if (showHotelModel) {
      const hotelParams = this.searchService.searches[ServiceType.Hotel];
      await this.searchParams.selectFlightSeatMapCheck(flightCopy).then(() => {
        if (!this.searchService.simultaneousHotelSearchRequested && hotelParams?.cheapestPrice?.amount) {
          const suggestedHotels = hotelParams.results.value.slice(0, 3);
          const options = {
            suggestedHotels: suggestedHotels
          };
          this.modalService.open(LightningModalTypes.HotelSuggestionModalComponent, { centered: true, backdrop: 'static' }, options).then((modalResult) => {
            if (modalResult) {
              hotelParams.backToType = ServiceType.Flight;
              this.searchService.search_objects[ServiceType.Flight].chosen = false;
              const searchObj = this.searchService.init_search_objects();
              const hotelResultUrl = searchObj[ServiceType.Hotel].resultUrl;
              this.searchService.search_objects[ServiceType.Hotel].priority = searchObj[ServiceType.Hotel].priority;
              this.searchService.search_objects[ServiceType.Hotel].resultUrl = hotelResultUrl;
              this.router.navigate([hotelResultUrl]);
            } else {
              this.searchService.search_objects[ServiceType.Hotel].chosen = false;
              hotelParams.reset();
            }
          });
        } else if (!this.searchService.simultaneousHotelSearchRequested && !hotelParams?.cheapestPrice?.amount) {
          //No hotel results found and they haven't selected hotels, so deselect hotels
          this.searchService.search_objects[ServiceType.Hotel].chosen = false;
        }
      });
    } else {
      await this.searchParams.selectFlightSeatMapCheck(flightCopy);
    }
  }

  async addToBasketDSFlight(flightFare: FlightItineraryWithExtensions) {
    const inflightCopy = <FlightItineraryWithExtensions>this.helpers.clone(flightFare);
    if (flightFare.requiresReasonKeys?.length) {
      inflightCopy.requiresReasonMessages = flightFare?.requiresReasonMessages;
    }
    await this.searchParams.selectFlightSeatMapCheck(
      inflightCopy,
      FlightDirectionEnum.DualSingleInbound
    );
    this.searchParams.resetSelectedDualSingle();
    this.selectedDSFlight = false;
  }

  getFareRules(flight: FlightItineraryWithExtensions): RuleActionExtension {
    return {
      unavailable: flight.unavailable,
      unavailableMessage: flight.unavailableMessage,
      requiresReasonKeys: flight.requiresReasonKeys,
      requiresReasonMessages: flight.requiresReasonMessages
    };
  }

  selectDualSingle(item: FlightItineraryWithExtensions) {
    this.selectedDSFlight = !this.selectedDSFlight;
    if (this.selectedDSFlight) {
      this.searchParams.selectedDualSingle(item);
    } else {
      this.searchParams.resetSelectedDualSingle();
    }
  }

  getTicketExpandAriaLabel() {
    try {
      let ticket = this.lastOutboundFlightJourney;
      const outTime = ticket.dateTimeDeparting.split('T')[1].slice(0, -3);
      const inTime = ticket.dateTimeArriving.split('T')[1].slice(0, -3);
      return `${ticket.marketingCarrierName} ${ticket.originAirport} ${outTime} to ${ticket.destinationAirport} ${inTime}`;
    } catch {
      return 'flight';
    }
  }

  getTicketSelectAriaLabel(flight) {
    try {
      let ticket = this.lastOutboundFlightJourney;
      const outTime = ticket.dateTimeDeparting.split('T')[1].slice(0, -3);
      const inTime = ticket.dateTimeArriving.split('T')[1].slice(0, -3);
      const price = `${flight.total.currency} ${flight.total.price};`;
      return `${price} ${flight.outboundFlights[0].cabinClass} ${ticket.marketingCarrierName} ${ticket.originAirport} ${outTime} to ${ticket.destinationAirport} ${inTime}`;
    } catch {
      return 'flight';
    }
  }

  showSpecialFares(flight: FlightItinerary): boolean {
    return this.helpers.getSpecialFare(flight);
  }

  dateTimeError() {
    if (!this.isRetrunWarningOpen) {
      this.isRetrunWarningOpen = true;
    }
    const title = this.translateService.instant('Warning!');
    const message = this.translateService.instant(
      'Please note the inbound flight departs prior to the outbound flight landing. This item has not been added to your basket. Please choose again.'
    );
    this.modalService.open(
      LightningModalTypes.BasicModalComponent,
      { centered: true },
      {
        title: title,
        body: message,
        options: {
          buttonOk: true,
          buttonOkText: 'Ok',
          buttonCancel: false,
          buttonOkAction: () => {
            return true;
          }
        },
        isLoading: false
      }
    );
  }

  showAltClass(combinedFlight: FlightItineraryWithExtensions): boolean {
    return (
      combinedFlight.inboundFlights.length &&
      combinedFlight.outboundFlights[0].cabinClass !== combinedFlight.inboundFlights[0].cabinClass
    );
  }
  checkFlightReturnAirport(item: FlightItineraryWithExtensions): boolean {
    if (item.outboundFlights.length && item.inboundFlights?.length) {
      if (
        item.outboundFlights[0].originAirport !==
        item.inboundFlights[item.inboundFlights.length - 1].destinationAirport
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  applyCustomCSS(): void {
    const customCSS = this.searchParams.getCustomCSS(this.flight.journeyHash);
    if (customCSS) {
      const styleElement = document.createElement('style');
      this.element.nativeElement.id = `custom_css_${this.flight.journeyHash}`;
      styleElement.appendChild(document.createTextNode(`app-flight-display#custom_css_${this.flight.journeyHash} {${customCSS}}`));
      this.element.nativeElement.appendChild(styleElement);
    }
  }

  selectedMultiCityByScheduleFlight(combinedFlight: FlightItineraryWithExtensions) {
    if (!this.deselectItem) {
      this.selectedMultiCityFlight.emit(combinedFlight);
    } else {
      this.deselectMultiCityFlight.emit(combinedFlight);
    }
  }
}