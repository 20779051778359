import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ModalOpenerService } from '@sabstravtech/obtservices/angular';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../vendor/components/base_components/base-componet';
import { BookerType } from '@sabstravtech/obtservices/angular';
import { LogonService, UserService } from '@sabstravtech/obtservices/angular';
import { LoginResponse, AuthInfo, LoginError, LoginErrorReason, RegExps } from '@sabstravtech/obtservices/base';
import { VERSION } from '../../../environments/version';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ThemeService } from '../../vendor/services/theme.service';
// import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-logon',
  templateUrl: './logon.component.html',
  styleUrls: ['./logon.component.scss']
})
export class LogonComponent extends BaseComponent implements OnInit {
  login_error: boolean = false;
  error_message: string = '';
  user_name: string = '';
  pass_word: string = '';
  loading: boolean = false;
  token = null;
  overwrite: string = '';
  loginSub: Subscription = new Subscription();
  loginAnnouncements: any;
  currentVersion = VERSION;
  username_error: boolean = false;
  password_error: boolean = false;
  showDigitsURL: boolean = false;
  hasSSOMessage: boolean = false;
  showPassword = false;
  inputType = 'password';
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  constructor(
    private modalService: ModalOpenerService,
    private router: Router,
    private logonService: LogonService,
    protected themeService: ThemeService,
    // private announcementService: AnnouncementService,
    private userService: UserService,
    // public auth: AuthService,
    title: Title,
    public translateService: TranslateService
  ) {
    super(title, translateService);
  }

  ngOnInit(): void {
    if (!window.location.search.includes('code=')) {
      this.logonService.checkLogin().then((isLoggedin: boolean) => {
        if (isLoggedin) {
          this.router.navigateByUrl('/main');
        } // get the logon announcements
        /*this.subscribe(this.announcementService.getLogonAnnouncements(), ann => {
          this.loginAnnouncements = this.announcementService.getDisplayAnnouncements('login');
          console.log('+++ Login Announcements: ', this.loginAnnouncements, ' +++');
          console.log('is this the loggin error');
        });*/
        this.setTitle(this.translateService.instant('logon - LightUK'));
      });
    }
  }

  isEmptyObject(obj: any): boolean {
    return obj && Object.keys(obj).length === 0;
  }

  login(again?: boolean): void {
    this.login_error = false;
    this.username_error = false;
    this.password_error = false;
    // this.userService.onBehalfUser = undefined;
    this.error_message = 'Please enter a username and password';

    if (this.user_name.length < 1 && this.pass_word.length < 1) {
      // If both are missing, add borders to both boxes
      this.password_error = true;
      this.username_error = true;
      this.login_error = true;
      return;
    }

    if (this.user_name.length < 1) {
      this.login_error = true;
      this.username_error = true;
      return;
    }

    if (this.pass_word.length < 1) {
      this.login_error = true;
      this.password_error = true;
      return;
    }

    this.error_message = '';
    this.loading = true;

    if (this.loginSub) {
      this.loginSub.unsubscribe();
    }

    this.loginSub = this.subscribe(
      this.logonService.login(this.user_name, this.pass_word, again),
      () => {
        this.loading = false;
        if (
          (this.logonService.loginResponse && this.logonService.loginResponse.firstLogin) ||
          this.userService.fullUserDetails.userData.value.booker_type === BookerType.Traveller
        ) {
          // this.modalService.open(
          //   LightningModalTypes.UserAdminModalComponent,
          //   {
          //     centered: true,
          //     size: 'lg',
          //     windowClass: 'extra-large'
          //   },
          //   {}
          // );
        } else {
          this.router.navigate(['search']);
          // add in google api for ENT-10478
          /* TODO get landing page
          this.subscribe(this.landingPageService.getLandingPageTemplateMarkUp('corporate-homepage'), data => {
            if (data) {
              this.router.navigate(['landingPageBrand']);
            } else {
              this.router.navigate(['search']);
            }
          }); */
        }
      },
      this.handleLoginError.bind(this)
    );
  }

  loadGoogleMapsApi() {
    const script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyC_I3019IO-Dzdx5Hn-7Hlp1socVWLYeno&libraries=places&callback=initMap';
    script.async = true;
    document.head.appendChild(script);
  }

  loginAgainModal(): void {
    this.modalService.open(LightningModalTypes.ModalComponent, { centered: true, backdrop: 'static' }, {}).then(success => {
      if (success) {
        this.login(true);
      }
    });
  }

  passwordExpiryModal(errorData: LoginResponse) {
    const authInfo: AuthInfo = new AuthInfo();
    Object.assign(authInfo, {
      username: this.logonService.authInfo.username,
      token: errorData.changeToken
    });
    const modalValues = {
      errorData: errorData,
      authInfo
    };
    this.modalService.open(LightningModalTypes.ModalPasswordExpiryComponent, {}, modalValues).then((authInfo2: AuthInfo) => {
      if (authInfo2) {
        console.log(authInfo2);
        this.user_name = authInfo2.username;
        this.pass_word = authInfo2.password;
        this.login();
      }
    });
  }

  handleLoginError(error: LoginError): void {
    if (error.error === 'Authentication failed; User password is expired') {
      this.expiredPassword();
    }

    if (error.name === 'LoginError') {
      switch (error.reason) {
        case LoginErrorReason.LOGED_IN:
          this.loginAgainModal();
          break;
        case LoginErrorReason.PASSWORD_EXPIRED:
          this.passwordExpiryModal(error.data as LoginResponse);
          break;
        case LoginErrorReason.AUTH_ERROR:
          this.error_message = error.message;
          console.log('errororor', this.error_message);
          this.login_error = true;
          break;
        case LoginErrorReason.SSO:
          this.hasSSOMessage = true;
          this.showDigitsURL = !!error.data;
          break;
      }
    } else if (error.statusText === 'Unauthorized' && error.error !== 'Authentication failed; User password is expired') {
      this.error_message = 'Invalid username or password. Please try again';
      console.warn(error.error);
      this.login_error = true;
    }
    this.loading = false;
  }

  /**
    @desc - close the error box
  **/
  closeErrors(): void {
    this.login_error = false;
    this.password_error = false;
    this.username_error = false;
  }

  /**
    @desc - attempt to recover the users password if a valid email supplied
  **/
  recoverPassword(expiredPassword?: boolean): void {
    this.username_error = false;
    this.password_error = false;
    this.login_error = false;
    if (new RegExp(RegExps.EMAIL_VALIDATOR).test(this.user_name)) {
      this.subscribe(
        this.logonService.recoverPassword(this.user_name),
        () => {
          this.modalService.open(LightningModalTypes.ModalRecoverPasswordComponent, { centered: true }, { expiredPassword: expiredPassword });
        },
        error => {
          this.error_message = error?.status === 429 ? error.error : 'An unknown error occured calling recover password, please try again.';
          this.login_error = true;
          this.loading = false;
        }
      );
    } else {
      this.error_message = 'Please supply a valid email address.';
      this.login_error = true;
      this.username_error = true;
      this.loading = false;
    }
  }

  /**
    @desc - attempt to recover the users password if a expided email supplied
  **/
  expiredPassword(): void {
    this.modalService.open(LightningModalTypes.ModalExpiredPasswordComponent, { centered: true }, {}).then(success => {
      if (success) {
        this.recoverPassword(true);
      }
    });
  }

  getTotalNoOfAnnouncements(): number {
    return 0;
    // return this.announcementService.getTotalNoOfAnnouncements();
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
    this.inputType = this.showPassword ? 'text' : 'password';
  }

  // authSso() {
  //   this.auth.loginWithRedirect();
  // }
}
