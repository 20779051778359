<ng-container *ngIf="!pureErrorMessageFromBE">
  <div cdkTrapFocus aria-live="assertive">
    <div class="modal-header">
      <h3
        class="modal-title"
        [hidden]="liveRailError || hotelCancelError || unConfirmedBookingError"
        i18n="@@modalNetworkErrorTitle"
        id="modal-header">
        An Error Occurred!
      </h3>
      <h3
        [hidden]="!liveRailError"
        class="modal-title"
        i18n="@@importantInformation"
        id="modal-header">
        Important information!
      </h3>
      <h3
        [hidden]="!hotelCancelError && !unConfirmedBookingError"
        class="modal-title"
        i18n="@@importantCancelInformation"
        id="modal-header">
        Important!
      </h3>
      <button
        type="button"
        class="close"
        aria-label="An Error Occured Close"
        ngbAutoFocus
        i18n-aria-label
        (click)="activeModal.close('Cross click')">
        <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
      </button>
    </div>
    <div class="modal-body" [hidden]="paymentCardError || hotelCancelError || miErrorMessage">
      <p [hidden]="unConfirmedBookingError">{{ errorMessage }}.</p>
      <ng-container *ngIf="!liveRailError && !unConfirmedBookingError">
        <p>
          <span i18n="@@modalNetworkErrorOccurred"
            >Something went wrong whilst trying to perform your request<ng-container
              *ngIf="!duplicateBooking"
              >,
            </ng-container></span
          >
          <ng-container
            *ngIf="
              !duplicateBooking && (userService.fullUserDetails.allDetails | async) as userData
            ">
            <span i18n="@@modalNetworkErrorContactAdmin"
              >you could try again but if the problem persists please report the issue to your
              travel administrator / support team on:</span
            >
            <span>
              <ng-container
                *ngIf="userData?.office?.supportPhoneNumber as phoneNumber; else defaultNumber">
                <ng-container *ngIf="phoneNumber?.number; else defaultNumber">
                  +{{ phoneNumber?.countryCode }}{{ phoneNumber?.areaCode
                  }}{{ phoneNumber?.number }}
                </ng-container>
              </ng-container>
            </span>
            <span i18n="@@modalNetworkErrorContactEmail">or email </span>
            <ng-container
              *ngIf="
                isUISupportEmail
                  ? supportEmail
                  : userData?.office?.supportEmail || supportEmail as emailData
              ">
              <a title="Support mail" href="mailto:{{ emailData }}">
                {{ emailData }}
              </a>
            </ng-container>
          </ng-container>
        </p>
      </ng-container>
      <ng-container *ngIf="unConfirmedBookingError">
        <p>
          <span i18n="@@modalNetworkErrorUnconfirmedBookingMessage">
            The airline has returned an "Unconfirmed Status" on the booking you have made and we are
            unable to confirm whether your basket was successful at this time. If the booking was
            successful you will receive a booking confirmation from them shortly. If in any doubt
            and you wish to speak to someone, please contact our helpdesk on
            <span
              ><ng-container
                *ngIf="userData?.office?.supportPhoneNumber as phoneNumber; else defaultNumber">
                <ng-container *ngIf="phoneNumber?.number; else defaultNumber">
                  +{{ phoneNumber?.countryCode }}{{ phoneNumber?.areaCode
                  }}{{ phoneNumber?.number }}
                </ng-container> </ng-container
              >.</span
            >
          </span>
        </p>
      </ng-container>

      <span [hidden]="!duplicateBooking" i18n="@@modalNetworkErrorInfo"
        >Please proceed with the current item or remove it from your basket.
      </span>

      <p *ngIf="!unConfirmedBookingError" i18n="@@modalNetworkErrorApologise">
        We apologise for any inconvenience this has caused, thank you.
      </p>
      <p *ngIf="unConfirmedBookingError" i18n="@@modalNetworkErrorApologiseUnconfirmBooking">
        We apologise for any inconvenience this may cause.
      </p>
    </div>
    <div class="modal-body payment-card-error-container" [hidden]="!paymentCardError">
      <p i18n="@@modalNetworkErrorInfo1">
        The payment card details provided have been declined and we are unable to proceed with your
        booking. To proceed with the booking please click below to add new card or cancel if you do
        not wish to proceed.
      </p>
      <p i18n="@@modalNetworkErrorInfo2">
        Your card has not been charged and your booking has not been confirmed.
      </p>
    </div>
    <div class="modal-body" [hidden]="!miErrorMessage">
      <p>
        {{ errorMessage }}
      </p>
      <p>
        <span i18n="@@cancelHotelErrorInfoNumberEmail1"
          >Please contact your travel administrator or support team on {{ supportNumber }} or
          email</span
        >
        <ng-container
          *ngIf="
            isUISupportEmail
              ? supportEmail
              : userData?.office?.supportEmail || supportEmail as emailData
          ">
          <a title="Support mail" href="mailto:{{ emailData }}">
            {{ emailData }}
          </a> </ng-container
        ><span i18n="@@cancelHotelErrorInfoNumberEmail2"
          >. They will be able to assist in rectifying the issue.</span
        >
      </p>
      <p i18n="@@cancelHotelErrorInfoNumberEmail3">We apologise for any inconvenience caused.</p>
    </div>
    <div class="modal-body cancel-hotel-error-container" [hidden]="!hotelCancelError">
      <p i18n="@@cancelHotelErrorInfo1">
        A problem has occurred whilst trying to cancel your booking.
      </p>
      <p i18n="@@cancelHotelErrorInfo2">
        If you are attempting to cancel a non-refundable booking, or a booking that is outside of
        the cancellation terms and conditions, you may still be charged. Your travel team will
        automatically cancel your booking and you will receive an email notification once complete.
      </p>
      <p i18n="@@cancelHotelErrorInfo3">
        For imminent cancellations for today / tomorrow, or if you are unsure about the cancellation
        terms & conditions that apply to your booking, please contact your travel team for help and
        support.
      </p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        [hidden]="!paymentCardError"
        class="modal_button_okay"
        (click)="addNewCard()"
        i18n="@@modalNetworkErrorAddNewCard">
        Add New Card
      </button>
      <button
        type="button"
        [hidden]="!paymentCardError"
        class="modal_button_okay"
        (click)="activeModal.close(true)"
        i18n="@@modalNetworkErrorCancel">
        Cancel
      </button>
      <button
        type="button"
        [hidden]="paymentCardError"
        class="modal_button_okay"
        (click)="activeModal.close(true)"
        i18n="@@modalNetworkErrorOK">
        Ok
      </button>
    </div>
  </div>

  <ng-template #defaultNumber>
    {{ supportNumber }}
  </ng-template>
</ng-container>

<ng-container *ngIf="pureErrorMessageFromBE">
  <div class="modal-header">
    <h3 class="modal-title" id="modal-header-warning">{{ errorLevel }}!</h3>
    <button
      type="button"
      class="close"
      aria-label="Close"
      ngbAutoFocus
      i18n-aria-label
      (click)="activeModal.close('Cross click')">
      <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ errorMessage }}</p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="modal_button_okay"
      (click)="activeModal.close(true)"
      i18n="@@modalErrorOK">Ok</button>
  </div>
</ng-container>
