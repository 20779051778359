import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LogonService, AuthHttpService, UserService } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-logon-from-email',
  imports: [
    CommonModule,
  ],
  standalone: true,
  templateUrl: './logon-from-email.component.html',
  styleUrl: './logon-from-email.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogonFromEmailComponent implements OnInit {


  constructor(
    private router: Router,
    private logonService: LogonService,
    private httpCallService: AuthHttpService,
    private userService: UserService
  ) {

  }

  ngOnInit(): void {
    console.log(`+++ Logon from email module! +++`);

    // now read the jwt from the url - if it does not exist - redirect to logon page
    console.log(window.location.href);

    const params = window.location.href.split('?');

    if (!params[1]) {
      this.router.navigate(['/logon']);
    }

    console.log(params[1]);


    const seperatedParams = params[1].split('&');
    const decodedParams = {};

    seperatedParams.forEach(param => {
      const nameAndVal = param.split('=');
      decodedParams[nameAndVal[0]] = nameAndVal[1];
    })


    const jwt = decodedParams['jwt'];
    console.log(jwt);
    if (!jwt) {
      this.router.navigate(['/logon'])
    }

    // now attempt to save the jwt token - then navigate to the search page
    try {
      this.logonService.clearLoginData();
      const jwtWithObject = this.httpCallService.handleResponse({jwt: jwt});
      if (decodedParams['redirect_url']) {
        console.log(`+++ Navigating to: ${decodeURIComponent(decodedParams['redirect_url'])} +++`);
        this.router.navigate([decodeURIComponent(decodedParams['redirect_url'])]);
      } else {
        this.router.navigate(['/search']);
      }

    } catch (error) {
      console.error(error);
      this.router.navigate(['/logon']);
    }

  }

}
