import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../../../vendor/classes/modal-types.enum';
import {
  FlightItineraryWithExtensions,
  Traveller,
  BaseEnterpriseSearchService
} from '@sabstravtech/obtservices/base';
import {
  EnterpriseSearchService,
  UserService,
  StorageService,
  FlightSeatMapSeats,
  InputFlightJourney,
  HelperRoutines
} from '@sabstravtech/obtservices/angular';
import { BaseModalComponent } from '../../../../modal-module/base-modal.component';

@Component({
  selector: 'app-flight-extras-dialog',
  templateUrl: './flight-extras-dialog.component.html',
  styleUrls: ['./flight-extras-dialog.component.scss']
})
export class FlightExtrasDialogComponent extends BaseModalComponent implements OnInit {
  // Inputs
  public flight: FlightItineraryWithExtensions = null;
  public travellers: Traveller[] = [];
  selectedSeat: FlightSeatMapSeats[] = [];
  disableButton = true;
  selectedSeatWithId: { [x: string]: FlightSeatMapSeats[]; } = {};
  loyaltyWithId: {
    [x: string]: { loyalty: string; index: number; userId: number; loyaltyProgram: string; }[];
  } = {};
  ancillaryOptions: InputFlightJourney[];
  additionalDetails: {
    [key: string]: any;
  };
  notValid: boolean = false;
  processedTravellers: string[] = [];
  notProcessedTravellers: Traveller[] = [];
  changesMade: string[] = [];
  displayItinerary = false;
  constructor(
    public activeModal: NgbActiveModal,
    public searchService: EnterpriseSearchService,
    public userService: UserService,
    public storageService: StorageService,
    private helpers: HelperRoutines
  ) {
    super(activeModal);
  }

  ngOnInit() {
    this.getTravellers();
    const newExtras = {
      advancedAncillaryOptions: [],
      baggageAllowance: [],
      reducedMobillityAssistance: [],
      flightExtras: [],
      luggagePerBooking: true,
      luggagePerPax: true,
      ancillaryPerBooking: true
    };
    // Initialize additional details object
    this.additionalDetails = this.flight.outboundFlights.reduce(
      (obj, item) => Object.assign(obj, { [item.id]: { ...newExtras } }),
      {}
    );
    if (this.flight.inboundFlights?.length) {
      Object.assign(
        this.additionalDetails,
        this.flight.inboundFlights.reduce(
          (obj, item) => Object.assign(obj, { [item.id]: { ...newExtras } }),
          {}
        )
      );
    }

    // Push the 1st passenger to the list
    this.notProcessedTravellers.push(this.travellers[0]);
    this.displayItinerary = this.userService.isBasketReplacementUserFavouriteSet();
  }

  getTravellers() {
    this.subscribe(this.searchService.travellerInformation, (travellers: Traveller[]) => {
      this.travellers = travellers;
    });
    console.log(this.travellers);
  }

  addToBasket() {
    console.log(this.loyaltyWithId);

    this.activeModal.close({
      seats: this.selectedSeatWithId,
      valid: true,
      additionalDetails: this.additionalDetails,
      loyalty: this.loyaltyWithId
    });
  }

  getSeat(seat: { selectedSeat: FlightSeatMapSeats; id: string; }, index = 0) {
    this.selectedSeat[index] = seat.selectedSeat;
    if (!this.selectedSeatWithId[seat.id]) {
      this.selectedSeatWithId[seat.id] = [];
    }
    if (seat.selectedSeat) {
      if (!this.changesMade.includes('seat')) this.changesMade.push('seat');
    } else {
      this.changesMade.splice(this.changesMade.indexOf('seat'), 1);
    }
    this.selectedSeatWithId[seat.id][index] = seat.selectedSeat;
    if (!seat.selectedSeat) {
      this.selectedSeatWithId[seat.id] = this.selectedSeatWithId[seat.id].filter(x => x);
    }
    // doing this otherwise change is not detected
    this.selectedSeatWithId = { ...this.selectedSeatWithId };


    // this.isValid();
  }

  getLoyalty(loyalty, index = 0, travellerId = 0) {
    if (!this.loyaltyWithId[loyalty.id]) {
      this.loyaltyWithId[loyalty.id] = [];
    }

    if (this.loyaltyWithId[loyalty.id].filter(l => l.index === index).length) {
      this.loyaltyWithId[loyalty.id][
        this.loyaltyWithId[loyalty.id].findIndex(l => l.index === index)
      ].loyalty = loyalty.loyalty;

      this.loyaltyWithId[loyalty.id][
        this.loyaltyWithId[loyalty.id].findIndex(l => l.index === index)
      ].loyaltyProgram = loyalty.loyaltyProgram;
    } else {
      this.loyaltyWithId[loyalty.id].push({
        loyalty: loyalty.loyalty,
        index: index,
        userId: travellerId,
        loyaltyProgram: loyalty.loyaltyProgram
      });
    }
  }

  flightExtras(
    extras: {
      data: any;
      flightIds: [string];
      updatingFlightId: string;
      travellerId: string;
      key: string;
      luggagePerBooking: boolean;
      luggagePerPax: boolean;
      ancillaryPerBooking: boolean;
    },
    travellerId
  ) {
    let additionalDetails = this.helpers.clone(extras.data);

    if (additionalDetails.flightExtras) {
      additionalDetails.flightExtras.userId = travellerId;
    }
    if (additionalDetails.advancedAncillaryOptions) {
      additionalDetails.advancedAncillaryOptions.userId = travellerId;
    }
    if (additionalDetails.baggageAllowance) {
      additionalDetails.baggageAllowance.userId = travellerId;
    }
    if (additionalDetails.reducedMobillityAssistance) {
      additionalDetails.reducedMobillityAssistance.userId = travellerId;
    }

    const { key } = extras;

    extras.flightIds.forEach(flightId => {
      if (additionalDetails[key] && this.additionalDetails[flightId][key]) {
        // Add the same extra to the other legs on the same journey
        if (additionalDetails[key].id) {
          if (!this.changesMade.includes(key)) this.changesMade.push(key);
          const newExtras = [
            ...this.additionalDetails[flightId][key],
            { ...additionalDetails[key] }
          ];

          Object.assign(this.additionalDetails[flightId], {
            [key]: newExtras
          });

          Object.assign(this.additionalDetails[flightId], {
            ['luggagePerBooking']: extras.luggagePerBooking
          });

          Object.assign(this.additionalDetails[flightId], {
            ['luggagePerPax']: extras.luggagePerPax
          });

          Object.assign(this.additionalDetails[flightId], {
            ['ancillaryPerBooking']: extras.ancillaryPerBooking
          });
        } else {
          this.changesMade.splice(this.changesMade.indexOf(key), 1);
          // Remove extras when choosing None
          const oldExtras = this.additionalDetails[flightId][key].findIndex(
            (extr: any) => extr.userId === extras.travellerId
          );
          this.additionalDetails[flightId][key].splice(oldExtras, 1);
        }
      }
    });
    console.log(this.additionalDetails);
  }

  setFlightNotValid(notValid: boolean) {
    this.notValid = notValid;
  }

  processedTab(travellerId: string) {
    // Process one by one until processedTravellers is full of travellers.
    this.processedTravellers.push(travellerId);

    const firstOfNotProcessedPax = this.travellers.filter(
      x => !this.processedTravellers.includes(x.id)
    )[0];

    if (firstOfNotProcessedPax) {
      this.notProcessedTravellers.push(firstOfNotProcessedPax);
    }
  }

  loyaltyChange(isChange: boolean) {
    if (isChange && !this.changesMade.includes('loyalty')) {
      this.changesMade.push('loyalty');
    } else if (!isChange) {
      this.changesMade.splice(this.changesMade.indexOf('loyalty'), 1);
    }
  }
}

LightningModalTypes.FlightExtrasDialogComponent.component = FlightExtrasDialogComponent;
